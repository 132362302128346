.form_opportunity_form {
  .text-grey {
    color: #656776 !important;
  }
  .border_grey {
    border: 1px solid #f5f5f7 !important;
  }
  .form-control-solid,
  .form-select-solid {
    background-color: #f9f9f9 !important;
    font-size: 13px !important;
  }
  input,textarea,select{
    font-weight: 400;
  }
}

.border_grey_info {
  border:  0.75px solid #C9CBD9 !important;
  padding: 23px;
  border-radius: 7px;
}
.btn-light-blue {
  background: #f1faff !important;
}
.text-shade-blue{
  color: #00A1F7 !important;
}