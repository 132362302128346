.spaces_card_img{
    img{
      width:30px !important;
      height:30px !important;
      border-radius:25px;
      @media screen and (max-width:576px){
        width:25px !important;
        height:25px !important;
      }
    }
    .symbol-group .symbol {
        position: relative;
        z-index: 0;
        margin-left: -20px !important;
        border: 1px solid black; 
        transition: all 0.3s ease;
    }
    .symbol_service{
        span{
            left: 8px !important;
            position: absolute !important;
            top: 9px !important;
            color: white !important;
            font-size: 9px !important;
            @media screen and (max-width:576px){
                left: 4px !important;
                top: 8px !important;
                font-size: 7px !important;
            }
        }
    }

}
.card_spaces{
    height: 194px !important;
    cursor: pointer;
    &:hover{
background-color: #f9f9f9 !important;
border: 1x solid #F9F9F9 !important;
box-shadow: 0 3px 10px #0000001A;
.icons_card_bar{
    visibility:visible;
  
}
    }
    .icons_card_bar{
        visibility: hidden;
       
    }
    
}
.bd-right-none{
    border-right: none !important;
}
.form_control_space {
    &:focus{
        border-color:  #e4e6ef !important;
    }
}
.w-60{
    width: 60% !important;
}
.fs-26px{
    font-size: 22px;
  }