.dzu-submitButton {
  display: none;
}
.dzu-inputLabelWithFiles {
  color: #ffffff;
  border-color: #004b87;
  background-color: #004b87;
  font-size: 0.925rem;
  border-radius: 0.475rem;
  padding: calc(0.65rem + 1px) calc(1.25rem + 1px);
  font-family: Poppins, Helvetica, Arial, sans-serif;
}
.dzu-dropzone {
  margin-top: 10px;
  border: 2px solid #ebedf3;
  border-radius: 0.85rem; 
}
.dzu-dropzone  .dzu-inputLabel {
  color: #004b87;
  font-size: 18px;
  font-weight: 500;
  font-family: Poppins, Helvetica, "sans-serif";
}
.dzu-dropzone span .btn.btn-icon {
  margin-left: 10px;
}
.activity-icons {
  display: flex;
}
.activity-icons .activity-inner{
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 7px;
  align-items: center;
}
.form-control.form-control-solid#editComment {
  border-radius: 0.85rem;
}
.activity-icons img {
  width: 45px;
  height: 45px;
}