

.document_upload_form{
    .dzu-dropzone{
        margin-top: 10px;
        border: 1px solid #ECECEC;
        border-radius: 5px;

        height: 224px;
    }
   
}
.text-grey {
    color: #656776 !important;
  }
  .border-radius-1rem{
    border-radius: 1rem !important;
  }