a,
button {
  cursor: pointer;
}
.kanban-container {
  position: relative;

  // height: 100%;
  .addition-svg {
    position: relative;
    top: 31px;
    &:hover {
      opacity: 0.6;
    }
  }
  .h-21px {
    height: 21px;
  }
}
.kanban-item {
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  background: #fff;
  padding: 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  .kanban-image-ui {
    width: 100%;
    height: auto;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &:hover {
    background-color: #f1faff;
  }
  h3 {
    cursor: pointer;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-width: 86%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bg-red {
    background-color: #a80022 !important;
    height: 23px !important;
    font-size: 12px;
    padding: 3px 8px !important;
  }
  span {
    font-size: 14px;
  }
  .captain-text {
    color: #004b87;
    font-weight: 600 !important;
  }
  .bg-light-blue {
    height: 23px !important;
    font-size: 12px;
    padding: 3px 8px !important;
    background-color: #0097a8 !important ;
    white-space: nowrap !important;
  }
}
.images-card {
  li {
    list-style-type: none;
  }
  img {
    height: 27px;
    width: 27px;
    border-radius: 25px;
    position: relative;
    margin-left: -16px;
  }
}
.kanban-item:not(:last-child) {
  margin-bottom: 20px;
}
// =======================================
.hide {
  display: none;
}
.isEditable {
  display: flex;
}
.kanban-container {
  display: flex;
  overflow-x: scroll;
}
.kanban-board {
  flex: 0 0 337px;
  margin-left: 0px;
  margin-right: 0;
  // height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  .kanban-drag {
    padding: 8px;
    margin-top: 21px;

    height: 100%;
  }
}
.kanban-board-header {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  background-color: #f6f6f6;
  border-radius: 4px;
}
.isEditable {
  box-shadow: inset 0 0 0 2px #004b87;
}
.kanban-title-board {
  width: 100%;
  margin: 10px;

  .main-div-style {
    // height: 46px !important;
    background-color: white !important;

    input {
      border: none !important;
    }
    form {
      border: none !important;
    }
  }
  .add-card {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    margin-left: 11px;
    margin-top: 11px;
  }
  // .kanban-board-add-card-hover{
  //   &:hover{
  //     .kanban-title-link{
  //       color: black !important;
  //     }
  //     .fa-plus {

  //       &:hover {
  //         color: black !important;
  //       }
  //       &:focus {
  //         color: black !important;
  //       }
  //     }
  //   }

  //   &:hover{
  //     color: black !important;
  //     // background-color: red !important;
  //   }
  // }

  .scroll-class {
    overflow-y: overlay !important;
    overflow-x: hidden !important;
    height: 80% !important;
    width: 100%;
  }
  .kanban-title-link {
    font-size: 15px;
    font-weight: 500 !important;

    margin-bottom: 0;

    border-radius: 3px;
    box-shadow: none;
    font-weight: 600;

    border: 0;
    width: calc(100% - 10px);
    color: #a1a5b7 !important;
    cursor: pointer;
    margin-left: 12px;
    position: relative;
    top: -7px;
    // &:hover{
    //   color: black !important;
    // }
  }
  h2 {
    font-size: 14px;
    font-family: 'Poppins';
    .fs-12px {
      font-size: 12px;
    }
  }
  &.kanban-add-card {
    display: flex;
    justify-content: space-between;

    align-items: start;
    &:hover {
      color: black !important;
    }
    &:focus {
      color: black !important;
    }
    &:active {
      color: black !important;
    }
    & > div {
      position: relative;
      width: 100%;
    }

    .fa-plus {
      position: absolute;
      left: -6px;
      top: calc(50% - 7px);
      color: #a1a5b7 !important;
      &:hover {
        color: black !important;
      }
      &:focus {
        color: black !important;
      }
    }
  }
}
.kanban-board-header-main {
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.kanban-divider {
  border-left: 1px solid #004b87;
  height: 16px;
  margin: 8px 8px 12px 4px;
}
.board-views-btn {
  button {
    margin-right: 4px;
    color: #172b4d;
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 6px 12px;
    text-decoration: none;
    white-space: normal;
    background-color: rgba(9, 30, 66, 0.04);
    box-shadow: none;
    border: none;
    min-height: 32px;
  }
  &.flexGrow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.card-margins {
  margin-left: 17px !important;
  margin-right: 17px !important;
}
.flexGrow {
  flex-grow: 1;
}
.rounded-pill-radius {
  background-color: #181c32 !important;
  border-radius: 50% !important;
  height: 27px;
  width: 27px;
}
.board-editing-target {
  display: inline-flex;
  align-items: center;
  h3 {
    font-size: 16px;
    font-weight: 600;
    padding: 0 12px;
    line-height: 1.2;
    color: #181c32;
    margin-bottom: 0;
  }
}
.kanban-header-facepile {
  display: flex;
  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: inline-flex;
      align-items: center;
      a {
        display: inline-flex;
        .member-avatar {
          width: 28px;
          height: 28px;
          border-radius: 100%;
          object-fit: cover;
          border-width: 2px !important;
        }
      }

      img {
        height: 29px;
        width: 29px;
        border-radius: 55px;
      }

      // &:nth-of-type(n + 5) {
      //   display: none;
      // }
    }
  }
}
@media (max-width: 575px) {
  .card-margins {
    margin: 0 !important;
  }
  .kanban-board {
    margin-top: 23px;
    .kanban-board-header {
    }
  }
  .kanban-board-header-main {
    flex-direction: column;
    .left {
      flex-wrap: wrap;
      form {
        width: 100% !important;
      }
      .side-margins-responsive {
        margin: 13px 13px 0 0 !important;
      }
      .kanban-divider {
        display: none;
      }
      .kanban-header-facepile {
        margin-left: 0 !important;
        margin-top: 15px;
      }
      .board-views-btn {
        &.flexGrow {
          margin-top: 15px;
        }
      }
    }
  }
}
// scroll
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scroll-class {
  overflow-y: overlay !important;
  overflow-x: hidden !important;
  width: 100%;
  height: 95% !important;
}
.header_kanban_ui {
  width: 280px !important;
  overflow: hidden;
  li {
    a {
      // width: 280px;
      // overflow: hidden;
    }
  }
}
