.talent-form tr {
  border-bottom: none !important;
}
.talent-form-custom-btn {
  border: 0;
  /* background-color: #004b87; */
  color: #ffffff;
  /* padding: 11.725px 23.75px; */
  font-weight: 500;
  line-height: 1.5;
  /* font-size: 1.15rem; */
  border-radius: 0.475rem;
  text-align: center;
}
