//
// Breadcrumb
//

// Breadcrumb
.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;

  // Item breadcrumb
  .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: $breadcrumb-item-padding-x;
    //text-transform: capitalize;
    color: #a1a5b7;
    a {
      color: #a1a5b7;
    }
    &.text-active,
    &:hover {
      color: #004b87;
      a {
        color: #004b87;
      }
    }
    &:last-child {
      padding-right: 0;
    }

    &:after {
      // content: '/';
      padding-left: $breadcrumb-item-padding-x;
    }

    &:before {
      display: none;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

// Breadcrumb line style
.breadcrumb-line {
  .breadcrumb-item {
    &:after {
      content: '-';
    }
  }
}

// Breadcrumb dot style
.breadcrumb-dot {
  .breadcrumb-item {
    &:after {
      content: '\2022';
    }
  }
}
// Breadcrumb dot style
.breadcrumb-lessthan {
  .breadcrumb-item {
    &:after {
      // content: '\003E';
      // content:"";
    }
  }
}

// Breadcrumb separatorless style
.breadcrumb-separatorless {
  .breadcrumb-item {
    &:after {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .breadcrumb-main{
    flex-direction: column;
    & > div:first-child {
      display: flex !important;
      width: 100%;
      padding-top: 15px;
    }
    & > div:nth-child(2){
      justify-content: flex-start;
      margin-top: 10px;
      width: 100%;
    }
  }
}