.border-chat-grey{
    border:1px solid rgb(161, 165, 183,.3) !important;
}
.text-dark-blue{
    color:#004B87 !important;
}
.para-grey{
 color:#A1A5B7 !important;
}
.symbol-position{
    position: relative;
    left: 30px;
}
.border-top-none{
    border-top: none !important;
}
.bg-dark-grey-chat{
    border-color: #A1A5B7 !important;
}
.custom_position{
    position: absolute;
    top: 14px;
    right: 4px;
}
.height_adjust_mobile{
    @media (max-width:576px) {
        height: auto !important;
    }
}
.inner-dots{
    position: absolute;
    right: 24px;
    /* top: 107px; */
    margin-top: 14px;
}
.rounded-border-text-light{
    border-radius:22px 0px 22px 22px !important;
    background-color: #E5F8FF !important;
}
.chats{
    overflow: scroll;
    height: calc(80vh - 205px);
}
.postion_set{
    right: -31px !important;
}