.custom-table {
  border-collapse: collapse !important;
  border-spacing: 10px;
  width: 100%;
  padding: 8px;
}
.custom-table .DeclarationBorder {
  border-bottom: 1px solid #000000 !important;
}

tr td table.custom-table {
  border-collapse: collapse !important;
  border: none !important;
}

.custom-cell span {
  font-family: sans-serif;
}

.custom-cell {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  background-color: transparent !important;
}
.custom-cell-th {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  width: 150px !important;
  background-color: transparent !important;
}

.custom-cell-th-first {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  width: 150px !important;
  background-color: transparent !important;
}
.custom-cell .paymentVia {
  margin-left: 140px !important;
}
.custom-cell-date-field-first {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  width: 255px !important;
  /* border: 0 !important; */
  background-color: transparent !important;
}

.custom-cell-date-first {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  width: 124px !important;
  /* width: auto; */
  /* border: 0 !important; */
  background-color: transparent !important;
}
.custom-cell-first {
  width: 300px !important;
}
.custom-cell-last {
  widows: auto;
}
.cnic-height {
  height: 36px !important;
}
.custom-cell-monthly-installment-suggested {
  width: 180px !important;
}
.custom-cell-monthly-installment {
  width: 160px !important;
  padding: 8px !important;
}
.custom-cell-value-first {
  width: 450px !important;
}
.custom-cell-value-last {
  width: 240px !important;
}
.custom-cell-date-field-last {
  width: 200px !important;
}
.custom-table tbody tr:last-child {
  border-bottom: 0 !important;
}
.custom-table tbody tr {
  border: 1px solid #000000 !important;
}
.custom-table tbody tr.custom-row-status .custom-cell,
.custom-table tbody tr.custom-row-status {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

/* .custom-table tbody tr.custom-row-declaration td.custom-cell,
.custom-table tbody tr.custom-row-declaration .custom-cell-th,
.custom-table tbody tr.custom-row-declaration {
  border-top: 0px !important;
} */

.custom-table tbody.custom-row-declaration tr:last-child td {
  border-bottom: 0 !important;
}

.font-size-11 {
  font-size: 11px;
}
.custom-cell-loan {
  border: 1px solid #000000;
  padding: 8px !important;
  text-align: left;
  width: 200px !important;
  /* border: 0 !important; */
  background-color: transparent !important;
}
.custom-table td.custom-cell-status {
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 8px !important;
}

.custom-table td.custom-cell-date:first-child {
  border: 0 !important;
}
/* .custom-table td.custom-cell-date:last-child {
  border-top: 0 !important;
  border-bottom: 0 !important;
} */
.custom-cell .square-box {
  width: 18px;
  height: 18px;
  border: 1px solid #000; /* Set border color as needed */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.square-box-uncheck {
  width: 13px;
  height: 13px;
  border: 1px solid #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.custom-cell-date-last {
  /* width: 120px !important; */
  width: auto;
}

.custom-cell-0 {
  width: 170px;
}

.custom-cell-1 {
  width: 209px;
  /* width: auto; */
}

.custom-cell-2 {
  width: 200px;
}
.custom-cell-3 {
  /* width: 180px; */
  width: auto;
}
/* .custom-cell .square-box span .bi-check{
  background-color: aquamarine;
} */

/* @media print {
  .bi::before {
    content: attr(class);
  }
} */

/* td.custom-cell {
  border: 1px solid #000000 !important;
  margin: 1px !important;
} */

/* Optional: Add some additional styling for a cleaner look */
.custom-table th,
.custom-table td {
  background-color: #f2f2f2;
  border: 1px solid #000000 !important;
}
table.custom-table tbody tr:last-child td {
  border: 1px solid #000000 !important;
}

/* .custom-table td.custom-td {
  border: 0px !important;
} */
td.custom-td .custom-table td:first-child {
  border-left: 0px !important;
}
td.custom-td .custom-table td:last-child {
  border-right: 0px !important;
}

.custom-table td.custom-td-new {
  border-top: 0px !important;
}

table.custom-table tbody td.custom-td tr:last-child td {
  border-bottom: 0px !important;
}
