.load-more_btn{
  font-size: 13px;
  color: #004b87 !important;
  padding: 0 !important;
}


.summaryPage {
  .bg-light-grey {
    background-color: #f5f8fa;
    p {
      color: #a1a5b7;
      font-size: 12px;
      margin-bottom: 2px;
    }
  }
  .color-light-grey {
    color: #a1a5b7;
    font-size: 12px;
  }
  .img-width {
    height: 53px;
    width: 53px;
    border: 2px solid white;
    position: relative;
    top: -29px;
    left: 17px;
  }
  .icon-style {
    margin-left: 9px;
  }
  .card {
    height: 100%;
    box-shadow: 0px 10px 20px #12263f08;
    .card-text {
      font-size: 11px;
      color: #a1a5b7;
    }
  }
  .card-Overveiw {
    box-shadow: 0px 10px 20px #12263f08;
    .direction-mobile {
      padding: 12px 12px 58px 12px;
    }
    p {
      color: #181c32;
      font-size: 12px;
      margin-bottom: 2px;
      .link-color {
        cursor: pointer;
        color: #004b87;
        opacity: 0.9;
        font-weight: 500;
      }
    }
    .point {
      font-size: 15px;
    }
    .chart-style {
      width: 100%;
      object-fit: scale-down !important;
    }
    .radial_bar_ui{
      width: 100%;
      .apexcharts-legend{
    //left:0!important;
    //border-left: 2px dashed lightgray;
    @media (max-width:1024px){
      //left:-13px;
      padding-left: 0 !important;
    }
    @media (max-width:820px){
      //left:-12px;
      padding-left: 0 !important;
    }

      }
    }
    .radial_chart_ui{
     
        transform: scaleX(-1);
        //position: relative;
        // width: 155%;
        // left: -285px; 
        z-index: 77;
        // border: 5px solid yellow;
        @media (max-width:1024px){
          // width:155% !important;
          // left:-189px !important;
        }
        @media (max-width:820px){
          // width:148% !important;
          // left:-197px !important;
        }
        
        .apexcharts-legend-text{
          display: flex !important;
          justify-content: space-between !important;
         align-items: center!important;
         width: 100% !important;
         @media (max-width:1024px){
          font-size: 12px;
         }
         .task-count {
          //font-size: 12px !important;
          margin-left: 40px;
          font-weight: 600 !important;
          @media (max-width:1024px){
            margin-left: 20px !important;
          }
          @media (max-width:820px){
            margin-left: 16px !important;
          }

          @media (max-width:767px){
            font-size: 14px !important;
            .task-count {
              font-size: 14px !important;
            }
          }
      }
        }
     .apexcharts-legend-series{
      display: flex !important;
      .apexcharts-legend-marker{
        margin-top: 2px !important;
        margin-right: 8px !important;
        clip-path: circle();
      }
      
     }
  
        @media (max-width:425px){
          left: 0 !important;
          width: 100% !important;
        }
     
    }
    .list-styling {
      @media (max-width: 540px) {
        width: 100% !important;
        margin-left: 0 !important;
      }
      @media (max-width: 768px) {
        border-left: 0 !important;
      }
      width: 50%;
      border-left: 4px dashed #f6f6f6;
      margin-left: 9px;
      ul {
        list-style: none;
        li {
          margin: 9px 0;
        }
        @media (max-width: 425px) {
          padding-left: 0 !important;
        }
      }
    }
    .management-div {
      border-left: 5px dotted #f6f6f6;

      padding-left: 21px;
    }
    %dots-style {
      border-radius: 50%;
      height: 9px;
      width: 9px;
      margin-top: 5px;
      margin-right: 12px;
    }
    .dots-position {
      background: #a1a5b7;
      @extend %dots-style;
    }
    .dots-position1 {
      background-color: #004b87;
      @extend %dots-style;
    }
    .dots-position2 {
      background-color: #181c32;
      @extend %dots-style;
    }
    .dots-position3 {
      background-color: #479800;
      @extend %dots-style;
    }

    span {
      font-weight: 500 !important;
      //white-space: nowrap;
      //font-size: 12px;
    }
  }
  .activity-card {
    p {
      color: #181c32;
      font-size: 12px;
      margin-bottom: 2px;
    }
    .font-12px {
      font-size: 12px;
      opacity: 0.8;
    }
    .font-bold-500 {
      font-weight: 600;
      color: #181c32;
    }
    .load-more_btn{
      font-size: 13px;
      color: #004b87 !important;
      padding: 0 !important;
    }
  }
  .accordion-style-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 98px;
    border: 1px solid #f6f6f6;
    box-shadow: 0 10px 20px #12263f08;
    background-color: white;
    p {
      margin-bottom: 12px;
    }
    .accord-image {
      object-fit: contain;
    }
  }
  .accordion-style-card p + p {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 6px;
  }
  .margins-responsive {
    @media (max-width: 991px) {
      margin-top: 23px;
    }
  }
}

@media (max-width: 767px) {
  .margins-res-media {
    margin: 23px 0 0 0 !important;
  }
  .mobile-alignment {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .mobile_display {
    display: none;
  }
}

.summaryPage .apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
  transform: scaleX(-1);
  max-width: 200px;
  @media (max-width: 1199px) {
    max-width: 160px;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    max-width: 200px;   
  }
}
