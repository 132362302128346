.display_mobile_none{
    @media (max-width:768px){
        display: none !important;
    }
}
.display_desktop_none{
    @media (min-width:769px){
        display: none !important;
    }
}
.topbar .btn.btn-icon .svg-icon svg [fill]:not(.permanent):not(g){
    fill: #a1a5b7 !important;
}