.page-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    // border-radius: 0.475rem;
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 10%);
    outline: 0;
    .page-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1.05rem 1.75rem;
        // border-bottom: 1px solid #eff2f5;
        // border-top-left-radius: 0.475rem;
        // border-top-right-radius: 0.475rem;
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1.75rem;
    }
}

.spaces_margins_adjust{
    @media (max-width:540px) {
        margin-left: 12px;
    }
}