.breadcrumb{
    font-size: 14px !important;
}
.spacing_img{
    // height: 11px !important;
    padding: 0 0px 0 8px !important;
}
.w-14px{
    width: 13px !important;
}
@media (max-width:507px) 
{
    .small-srn-dir{
       display: flex;
       flex-direction: column !important;
       .bg-lighter{
        // align-items: end !important;
       }
    }
    
}
.text-name-dot{
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 50%;
}
.breadcrumb-contents{
    display: contents !important;
}

