body {
  background-size: 100% 210px !important;
}

.chatInner{
  overflow: scroll;
  // height: calc(80vh - 175px); 
  .chatbox{
    height: calc(80vh - 275px); 
    overflow-y: scroll;

  }
}


.tabs-main {
  // background-color: #fff;
  // display: flex;
  border-radius: 0.475rem;

  .row {
    flex-wrap: wrap;
  }

  .card {
    &.card-custom {
      background-color: transparent;
    }

    .card-header {
      justify-content: center;
      border-bottom: 0;

      .nav-line-tabs {
        .nav-item {
          align-items: center;

          .nav-link {
            width: 12px;
            height: 12px;
            background-color: #eee;
            border-radius: 100%;
            padding: 0;
            border: 1px solid #eee;
            margin: 0 0.5rem;

            &.active {
              background-color: transparent;
            }
          }
        }
      }
    }

    .tab-pane {
      background-color: #fff;

      .card-item {
        box-shadow: none;
        background-color: transparent;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .icon {
          height: 63px;
          width: 63px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;

          img {
            width: 63px;
            margin: 0 auto;
            display: flex;
          }
        }

        &.disabled {
          .title {
            h6 {
              opacity: 0.7;
              columns: #181c32;
            }
          }
        }

        &:hover {
          &:not(.disabled) {
            .title {
              h6 {
                color: #004b87;
              }
            }
          }

          // .title {
          //   h6 {
          //     color: #004b87;
          //   }
          // }
        }
      }

      .demo-icons {
        .card-item {
          height: auto !important;

          a {
            .icon {
              // background-color: #35C7EE;
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;

              svg,
              img {
                // height: 32px;
                width: 50%;
                margin: 0 auto;
                display: flex;
                fill: #fff;
              }
            }
          }
        }

        &.two {
          .card-item {
            height: 160px;

            a {
              .icon {
                // background-color: #D43F55;
                width: 120px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                svg,
                img {
                  // height: 55px;
                  width: 50%;
                  margin: 0 auto;
                  display: flex;
                  fill: #fff;
                }
              }
            }
          }
        }

        &.three {
          .card-item {
            height: 190px;

            a {
              .icon {
                // background-color: #CD9837;
                width: 152px;
                height: 152px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                svg,
                img {
                  // height: 67px;
                  width: 50%;
                  margin: 0 auto;
                  display: flex;
                  fill: #fff;
                }
              }
            }
          }
        }

        div {
          .card-item {
            a {
              .icon {
                background-color: #004b87;
              }
            }
          }
        }

        //   &[title="Customer Master"] {
        //     .card-item {
        //       a {
        //         .icon {
        //           background-color: #D43F55;
        //         }
        //       }
        //     }
        //   }
        //   &[title="Customer Onboarding"] {
        //     .card-item {
        //       a {
        //         .icon {
        //           background-color: #CD9837;
        //         }
        //       }
        //     }
        //   }
        //   &[title="Resource Onboarding"] {
        //     .card-item {
        //       a {
        //         .icon {
        //           background-color: #35C7EE;
        //         }
        //       }
        //     }
        //   }
        //   &[title="Customer Invoices"] {
        //     .card-item {
        //       a {
        //         .icon {
        //           background-color: #9DB63A;
        //         }
        //       }
        //     }
        //   }
        //   &[title="Reports"] {
        //     .card-item {
        //       a {
        //         .icon {
        //           background-color: #4C4F9F;
        //         }
        //       }
        //     }
        //   }
        // }
        // .card-item {
        //   box-shadow: none;
        //   background-color: transparent;
        //   height: 140px;
        //   a {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     text-align: center;
        //   }
        //   .icon {
        //     width: 110px;
        //     height: 110px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     border-radius: 8px;
        //     svg, img {
        //       height: 45px;
        //       margin: 0 auto;
        //       display: flex;
        //     }
        //   }
        //   &.disabled {
        //     .title {
        //       h6 {
        //         opacity: 0.7;
        //         columns: #181c32;
        //       }
        //     }
        //   }
        //   &:hover {
        //     &:not(.disabled) {
        //       .title {
        //         h6 {
        //           color: #004b87;
        //         }
        //       }
        //     }
        //     // .title {
        //     //   h6 {
        //     //     color: #004b87;
        //     //   }
        //     // }
        //   }
        // }
      }
    }
  }

  .card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 130px;
    justify-content: center;
    text-decoration: none;

    .title {
      h6 {
        font-size: 12px;
        font-weight: 500;
        font-family: 'Poppins', 'Helvetica', 'sans-serif';
        margin-top: 12px;
        text-align: center;
        // text-transform: capitalize;
      }
    }
  }
}

.filters {
  label {
    font-size: 13px;
    color: #a1a5b7;
  }

  .multi-select__placeholder,
  .multi-select-container,
  input,
  input::placeholder,
  select {
    font-size: 13px;
    color: #181c32 !important;
    font-weight: 400 !important;
  }
}

.login-logo {
  color: #004b87 !important;
  font-size: 28px;
  font-weight: 700;
}

.no-item-found {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 400px;
    margin: 0 30px;
  }
}

.spaces-members {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

.spaces-key-date {
  .spaces-key {
    width: 70%;

    b {
      width: max-content;
      min-width: max-content;
    }
  }

  .date {
    font-size: 12px;
    min-width: 115px;
    justify-content: flex-end;

    white-space: nowrap;

    b {
      display: none;
    }

    @media (max-width: 540px) {
      & {
        justify-content: flex-start;
      }
    }
  }
}

.date-field {
  background-image: none !important;
  padding-right: 1rem !important;
  position: relative;
  min-height: 41px;
  max-height: 41px;

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator {
    // display: none;
    opacity: 0;
    cursor: pointer;
    // -webkit-appearance: none;
  }

  &:after {
    content: '';
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    cursor: pointer;
    position: absolute;
    top: 13.9px;
    right: 1rem;
    pointer-events: none;
  }
}

.feature-opacity {
  opacity: 0.6;

  &.active {
    opacity: 1;

    .selected-feature {
      display: flex;
      z-index: 1;
    }

    .title {
      span {
        color: #004b87;
        font-weight: 500;
      }
    }
  }

  &:hover {
    &:not(.disabled) {
      opacity: 1;

      .title {
        span {
          font-weight: 500;
        }
      }
    }
  }

  .title {
    span {
      color: #181c32;
    }
  }

  a.icon {
    min-height: 75px;
  }

  .selected-feature {
    display: none;
    top: -3px;
    right: 0;
  }

  i.fa-check {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6px;
    padding: 4px;
  }
}

.multi-select__menu-list {
  max-height: 160px;
}

.multisel-custom-select {
  display: flex;
  justify-content: space-between;
  background-color: #f5f8fa;
  border-color: #f5f8fa;

  &>div {
    width: calc(100% - 70px);
    background-color: #f5f8fa;
    border-radius: 4px 0 0 4px;
  }

  border-radius: 4px 0 0 4px;

  .multi-select-container {
    width: 100%;

    .multi-select__control {
      border-radius: 4px 0 0 4px;
    }
  }

  &>a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.custom-smart-select-popup {
  .modal-header {
    border-bottom: 2px solid #eee;
  }

  .modal-content {
    background-color: #f6f6f6;
    box-shadow: 0px 2px 6px #3b3b3b26;
    border-radius: 1rem !important;
  }

  .modal-body {
    margin: 7px;

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      border: 2px solid #707070;
      border-radius: 4px;
      opacity: 1;
      background-color: #fff;

      &:checked {
        border: 2px solid #004b87 !important;
      }
    }
  }

  .left {
    border-radius: 4px;
    background-color: #fff;
    // height: 100vh;
    padding: 0 20px;
    height: 539.1px;
    overflow: scroll;
  }

  .right {
    border-radius: 4px;
    background-color: #fff;
    // height: 100vh;
    border-left: 7px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .aside {
      .card-body {
        height: 365px;
        overflow: scroll;

        &>div {
          min-height: 60.48px;
        }
      }
    }
  }

  table {
    tr {
      th {
        padding-top: 1.7rem !important;
        padding-bottom: 1.7rem !important;
        text-transform: uppercase;
        font: normal normal medium 14px/21px Poppins;
        color: #3b3b3b;
        font-weight: 500;
      }

      th,
      td {
        vertical-align: middle;
      }

      td {
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0px;
        color: #727272;
        height: 60.48px;

        a {
          text-transform: lowercase;
          font: normal normal normal 14px/21px Poppins;
          letter-spacing: 0px;
          color: #727272;
        }

        &:not(:first-child) {
          margin-left: -5px;
        }
      }
    }
  }

  .custom-select-accordion {
    .card {
      &:first-child {
        .card-header {
          // min-height: 70px;
        }
      }

      .card-header {
        align-items: center;
        cursor: pointer;

        // min-height: 60.48px;
        &:after {
          content: '\f107';
          font-family: 'Font Awesome 5 Free';
          font-weight: 600;
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid;
          border-radius: 100%;
          margin-left: 10px;
          font-size: 14px;
          line-height: 22px;
        }

        &.collapsed {
          background: #a541bb;
          color: #fff;
        }

        &.collapsed:after {
          content: '\f106';
        }
      }
    }
  }
}

#kt_modal_assignees_list_app {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font: normal normal normal 15px/26px Poppins;
  letter-spacing: -0.32px;
  color: #ffffff;
  text-transform: capitalize;
  height: 41px;

  .icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
  }
}

#kt_chat_messenger {
  border-left: 1px solid #e5e9ed;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// .back-btn {
//   background-color: #004b87;
//   border-color: #004b87;
//   border: 1px solid;
//   color: #fff;
//   padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
//   border-radius: 0.475rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   & i {
//     color: #fff;
//   }
//   &:hover {
//     background-color: #fff;
//     color: #004b87;
//     & i {
//       color: #004b87;
//     }
//   }
// }
.bg-gray {
  background-color: #f5f8fa !important;
}

.text-gray {
  color: #eee !important;
}

.bg-dark-gray {
  background: #e5e9ed !important;
}

.text-dark-gray {
  color: #e5e9ed !important;
}

.masonry-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100px !important;
}

.masonry-btn {
  font-size: 11px !important;
}

.masonry-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

table {
  border-collapse: unset !important;
  border-spacing: 0 !important;
}

td:not(:first-child) {
  padding-left: 10px !important;
}

td {
  padding-bottom: 10px !important;
}

td {
  padding-top: 10px !important;
}

td:not(:last-child) {
  padding-right: 10px !important;
}

th:not(:first-child) {
  padding-left: 10px !important;
}

th {
  padding-bottom: 10px !important;
}

th {
  padding-top: 10px !important;
}

th:not(:last-child) {
  padding-right: 10px !important;
}

tr {
  border-bottom: 1px dashed #e4e6ef !important;
  border-spacing: 0;
}

// =================================== chat-module-box
.chat-module-box {
  min-height: 77vh;

  &>div {
    border-top-left-radius: 0.475rem !important;
    border-top-right-radius: 0.475rem !important;
  }

  .left-bar {
    ul {
      li {
        &.active {
          border-right: 4px solid #004b87;
        }
      }
    }
  }

  .card-footer {
    position: relative;

    &#kt_chat_messenger_footer {
      textarea {
        padding-right: 40px !important;
        resize: none;
      }

      button {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
      }
    }
  }

  .right {
    width: calc(100% - 400px);
  }
}

.border-top-left-radius {
  border-top-left-radius: 0.475rem;
}

.border-top-right-radius {
  border-top-right-radius: 0.475rem;
}

.border-bottom-left-radius {
  border-bottom-left-radius: 0.475rem;
}

.border-bottom-right-radius {
  border-bottom-right-radius: 0.475rem;
}

@media (max-width: 1199.98px) {
  .chat-module-box {
    .right {
      width: 100%;
    }
  }
}

@media (max-width: 991.98px) {
  .custom-smart-select-popup .right .aside {
    width: 100%;
  }

  .custom-smart-select-popup .right {
    border-left: 0;
  }
}

@media (max-width: 767.98px) {
  .tabs-main {
    .card {
      .tab-pane2 {
        .card-item {
          height: 100px;

          .icon {
            height: 55px;
            width: 55px;

            img {
              width: 53px;
            }
          }
        }
      }
    }
  }

  // .logo-sticky {
  //   height: 28px;
  // }
}

@media (max-width: 575px) {
  .no-item-found {
    img {
      width: 240px;
      margin: 0 30px;
    }
  }

  .spaces-key-date {
    flex-direction: column;

    .spaces-key {
      width: 100%;

      b {
        min-width: 80px;
      }
    }

    .date {
      b {
        display: inline-flex;
      }
    }
  }
}

.text-hover-opacity-100 {
  &:hover {
    opacity: 1 !important;
  }
}

.create_project_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .create_project_cards {
    height: 100%;

    .card {
      cursor: pointer;
      border: 1px solid #eff2f5;
      padding: 20px;
      margin-bottom: 20px;

      &.active {
        background: #f5f8fa;
      }

      &:hover {
        background: #f5f8fa;
      }

      h5 {
        color: #181c32;
      }

      p {
        color: #a1a5b7;
        margin-bottom: 5px !important;
      }

      .fa-angle-right {
        font-size: 19px;
        font-weight: 600;
      }
    }

    &.disable-hover {
      .card:hover {
        cursor: default;
        background: none;
      }

      .active:hover {
        background: #f5f8fa;
      }
    }
  }

  .form-control {
    font-size: 12px;
    color: #a1a5b7 !important;
  }

  .font-small {
    font-size: 13px;
  }

  .opposite-direction-responsive {
    @media (max-width: 913px) {
      & {
        flex-direction: column;
      }
    }
  }

  .fv-row {
    width: 50%;

    @media (max-width: 913px) {
      & {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 400px) {
    .buttons-responsive {
      flex-direction: column;
      justify-content: center;

      width: 100%;

      button {
        margin-bottom: 12px;
      }
    }

    .p-sm-2px {
      padding: 2rem !important;
    }

    .mr-sm-0 {
      margin-right: 0 !important;
    }
  }

  .border-grey {
    border: 1px solid #eff2f5;
    height: fit-content;
  }
}

.tags_ui {
  background: #f1faff;
  width: fit-content;
  white-space: nowrap;
  border-radius: 5px;
  height: 30px !important;
  min-height: 26px !important;
  color: #0368cd;
}

.text-truncate-name {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width:576px) {
    width: 180px;
  }
}

@media screen and (max-width: 576px) {
  .btnMTop{
    margin-top: -4.5rem !important;
    margin-right: -1.5rem;
  }
}
.fs-20px {
  font-size: 20px;
}