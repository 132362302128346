.list-todo-description {
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 25px;
    padding-bottom: 0px;
    max-width: 93%;
 height: 45px !important;
    // padding-right: 20px;
  }
}

.list-todo-assigee {
  // padding-right: 20px;
  width: fit-content;
  display: inline-flex;
}
// .flex-class > *{
//   flex: 1;
// }

.legend-card-main {
  // min-height: 70vh;
  .card-header {
    padding-top: 2px !important;
  }
  & > .card {
    flex-grow: 1;
  }
  .list-view-main {
    .list-box {
      span {
        margin-right: 7px;
      }
      .title-main {
        display: flex;
        .title {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0;
          width: 90%;
        }
      }
      .symbol {
        // display: -webkit-box;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0;
        word-wrap: break-word;
        width: 90%;
        flex-shrink: initial;
        img {
          height: 20px;
          width: 20px;
          &:first-child {
            margin-right: 3px;
          }
        }
      }
    }
  }
  table {
    tbody {
      tr {
        td {
          &[data-label='Description'] {
            span {
              width: 120px;
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
            }
          }
          &[data-label='Assignees'] {
            & > div {
              width: 140px;
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
            }
          }
          &[data-label='Actions'] {
            div {
              // justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
.btn.ligh-btn {
  color: #004b87;
  border-color: #f1faff;
  background-color: #f1faff;
  height: 36.94px;
  &.active,
  &:hover {
    color: #ffffff !important;
    border-color: #004b87;
    background-color: #004b87 !important;
    & .svg-icon svg [fill]:not(.permanent):not(g) {
      fill: #ffffff;
    }
  }
  & .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: #004b87;
  }
}

.legend-body {
  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 15px;
  }
  h3 {
    display: inline-flex;
    align-items: center;
    padding-right: 15px;
    text-transform: uppercase;
    font-size: 13.35px;
    color: #a1a5b7;
  }
  .periority-low {
    .progress-bar {
      width: 50px;
    }
  }
  .periority-normal {
    .progress-bar {
      width: 50px;
    }
  }
  .periority-high {
    .progress-bar {
      width: 50px;
    }
  }
}

.todo-bar-search-from {
  border: 1px solid rgb(238, 238, 238);
  width: 230px;
  height: 35px;
  input {
    border: none !important;
    padding: 26px 16px;

    font-size: 13px;
    &:focus-visible {
      box-shadow: none !important;
      border: none !important;
      outline: none;
    }
  }
}
.form-search-dimention {
  width: 100% !important;
  input {
    border: none !important;

    padding: 13px;
    height: 44px;
    &:focus-visible {
      border: 1px solid white !important;
      box-shadow: none !important;
      outline-color: white !important;
    }
    &:focus {
      border: 1px solid white !important;
      box-shadow: none !important;
      outline-color: white !important;
    }
    &:active {
      border: 1px solid white !important;
      box-shadow: none !important;
      outline-color: white !important;
    }
  }
}
@media (max-width: 1199.98px) {
  .list-todo-description {
    max-width: 600px;
  }
  .legend-body {
    order: 2;
    justify-content: flex-start;
  }
}

@media (max-width: 767.98px) {
  .legend-body {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .legend-card-main {
    // .card-header {
    //   min-height: auto !important;
    //   .card-title {
    //     order: 1;
    //   }
    //   #kt_header_search {
    //     order: 2;
    //   }
    //   .card-toolbar {
    //     order: 3;
    //   }
    //   .legend-body {
    //     order: 4;
    //     justify-content: flex-start;
    //   }
    //   .card-toolbar {
    //     padding-top: 10px;
    //   }
    // }
    .list-view-main {
      .list-box {
        .list-todo-description {
          max-width: 100%;
          .description {
            margin-right: 0;
            padding-right: 20px;
          }
        }
        .title-main {
          .title {
            padding-right: 20px;
          }
        }
      }
    }
  }
  .list-todo-description {
    max-width: 400px;
  }
}
.pagination-entries-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 630px) {
  .legend-card-main {
    .card-header {
      .card-toolbar {
        width: 100%;
        padding-top: 15px !important;
      }
      .legend-body {
        padding-left: 0 !important;
      }
    }
  }
}
@media (max-width: 575.98px) {
  table {
    &.todo-table, &.project-table, &.avengersInitiatives-table, &.organization-table, &.chart-table {
      thead {
        display: none;
      }
      tbody {
        tr {
          display: flex;
          flex-wrap: wrap;

          &:not(:last-child) {
            border-bottom: 1px solid rgb(221, 221, 221) !important;
          }
          td {
            border-bottom: 0px solid #ddd;
            align-items: center;
            min-width: 100%;
            width: 100% !important;
            font-size: 13px;
            display: inline-flex;
            padding-top: 0 !important;
            padding-left: 0 !important;
              padding: 1rem 0.75rem 1rem 0 !important;
            &[data-label='Actions'] {
              padding: 0 0.75rem 0.75rem 0 !important;
              div {
                justify-content: flex-start;
              }
              .btn-icon {
                height: 28px;
                width: 28px;
                svg {
                  height: 14px !important;
                  width: 14px !important;
                }
              }
            }
            span {
              font-size: 13px;
              width: auto !important;
              min-width: auto !important;
            }
            &::before {
              content: attr(data-label);
              float: left;
              font-weight: 600;
              text-transform: uppercase;
              // min-width: 30%;
              width: 100%;
              max-width: 40%;
              text-align: left;
              font-size: 12px;
              text-transform: capitalize;
            }
            .titleName,.groupsName{
              span{
                width: 120px !important;
              min-width: 120px !important;
              }
            }
            .projectTitle,.internalInitiativesTitle,.chartTableTitle{
              width: 150px !important;
              min-width: 150px !important;
            }
          }
        }
      }
    }
  }

  .legend-card-main {
    // .card-header {
    //   padding-top: 20px !important;
    // }
    table {
      tbody {
        tr {
          td[data-label='Description'] {
            span {
              width: 100px;
            }
          }
        }
      }
    }
    // .card-header {
    //   .card-toolbar {
    //     margin-top: 10px !important;
    //   }
    // }
  }

  .legend-body {
    padding-right: 1rem;
    padding-left: 0;
    justify-content: flex-start;
    & > div {
      margin-top: 10px;
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    h3 {
      font-size: 12px;
    }
  }

  .list-todo-description {
    max-width: 98%;
    -webkit-line-clamp: 2;
  }

  .pagination-entries-box {
    flex-direction: column;
    justify-content: center;
  }
  .flexColumnClass{
    flex-direction: column;
  }

  .membersTable{
    tbody{
      tr{
        td{
          @media screen and (max-width:576px) {
            padding: 1rem 0.75rem 1rem 0 !important;
            &[data-label='Name']{
              div{
                
                width: 150px !important;
                min-width: 150px !important;
              }
            }
            &[data-label='Email']{
              div{

                width: 150px !important;
                min-width: 150px !important;
              }
            }
          }
        }
      }
    }
  }
  .releaseNote{
    td{
      div{
        @media screen and (max-width:576px) {
          width: 140px !important;
          min-width: 140px !important;
          text-align: left;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .list-view-main {
    .list-box {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
    }
    .icons {
      width: calc(100% - 60px);
      left: 30px;
      position: relative;
      margin-top: 10px;
    }
  }
  .legend-body {
    .progress-bar {
      width: 40px !important;
    }
  }
  .legend-card-main {
    .card-header {
      #kt_header_search {
        .card-toolbar {
          .btn-icon {
            height: 26px;
            width: 26px;
          }
          button:not(.btn-icon) {
            padding: calc(0.4rem + 1px) calc(0.8rem + 1px);
            font-size: 11px;
            height: 28px;
          }
        }
      }
    }
  }
}
.table_border_lite {
  tr {
    border-bottom: 1px solid #eeeeee !important;
  }
}