.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #f1faff !important;
  color: #0368cd !important;
  border-color: transparent;
}
.text-light-grey-pagination {
  color: #a1a5b7 !important;
}
