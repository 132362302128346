.prof-icon{
    position: relative;
    top: 63px;
}
.border-top-none{
    border-top: none !important;
}
.profile_label{
    label{
        white-space: nowrap;
    }
   
}
.btn-blue-profile{
    height: 33px !important;
    padding: 0px 25px !important;
}
.width-mob-desk{
    width: 60%;
    @media (max-width:520px){
        margin-top: 50px;
        width: 100%;
    }
}
.mob-direction{
    @media (max-width:520px){
     flex-direction: column;
    } 
}
.margins_profile{
    @media (max-width:520px){
        margin:auto !important
       } 
}


@media (min-width:770px){
    .margins-larger-screens{
        margin-left: 40px !important;
    }
}
.z-index-78{
    z-index: 78 !important;
}
.fs-15px{
    font-size: 13px;
}