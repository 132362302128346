.timeline {
  &.timeline-3 {
    .timeline-items {
      margin: 0;
      padding: 0;
      .timeline-item {
        margin-left: 25px;
        border-left: 2px solid #ebedf3;
        padding: 0 0 20px 35px;
        position: relative;
        &:last-child {
          border-left-color: transparent;
          padding-bottom: 0;
        }
        .timeline-media {
          position: absolute;
          top: 0;
          left: -26px;
          border: 2px solid #ebedf3;
          border-radius: 100%;
          width: 50px;
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background-color: #ffffff;
          line-height: 0;
          img {
            width: 48px;
            height: 48px;
            border-radius: 14%;
          }
        }
        .timeline-content {
          border-radius: 0.85rem;
          position: relative;
          background-color: #f9f9f9;
          // background-color: #f3f6f9;
          padding: 0.75rem 1.5rem;
        }
      }
    }
  }
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: calc(1.35em + 1.1rem + 2px);
}
.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  .navi-header {
    padding: 0.75rem 1.5rem;
  }
  .navi-item {
    padding: 0;
    display: block;
    list-style: none;
  }
  .navi-separator {
    margin: 0;
    padding: 0;
    height: 0;
    display: block;
    border-bottom: 1px solid #ebedf3;
  }
  .navi-footer {
    padding: 0.75rem 1.5rem;
  }
}
.font-size-lg {
  font-size: 1.08rem;
}
.text-muted {
  color: #b5b5c3 !important;
}

.p-0 {
  padding: 0 !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.text-dark-75 {
  color: #3f4254 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}

.label {
  &.label-inline {
    width: auto;
    padding: 0.15rem 0.75rem;
    border-radius: 0.42rem;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
// .btn.btn-icon.btn-sm,
// .btn-group-sm > .btn.btn-icon {
//   height: calc(1.35em + 1.1rem + 2px);
//   width: calc(1.35em + 1.1rem + 2px);
// }
// .btn.btn-icon {
//   display: inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   padding: 0;
//   height: calc(1.5em + 1.3rem + 2px);
//   width: calc(1.5em + 1.3rem + 2px);
// }
.menu-rounded{
  border-radius: 8px !important;
}