.modal_maintainers {
  form {
    border: 1px solid #eeeeee;
    border-radius: 3px;
  }
  .modal-header {
    border-bottom: none !important;
  }
  .head_ui {
    color: #a1a5b7 !important;
    font-size: 12px !important;
    text-transform: capitalize;
  }
  .head_ui_input {
    border: 2px solid #a1a5b7 !important;
    width: 17px !important;
    height: 17px !important;
    opacity: 0.4 !important;
    background-color: white !important;
  }
  .text-black-grey {
    color: #313132 !important;
  }
  //   .tags_ui {
  //     background: #f1faff;
  //     width: 77px;
  //     white-space: nowrap;
  //     border-radius: 5px;
  //     font-size: 11px !important;
  //     /* padding: 17px; */
  //     display: flex;
  //     text-align: center;
  //   }
  .tags_ui {
    background: #f1faff;
    width: fit-content;
    white-space: nowrap;
    border-radius: 5px;
    height: 30px !important;
    min-height: 26px !important;
    label {
      color: #0368cd;
      font-size: 11px !important;
    }
  }
  .form_ui_search {
    border: 1px solid #eeeeee;
    border-radius: 5px !important;
    color: #c2c2c2;
  }
  .border-grey-lite {
    border: 1px solid #f5f5f7 !important;
  }
  .right {
    border-left: none !important;
    .card-body {
      overflow: unset !important;
    }
  }
  .maintainer_table {
    tr {
      border-bottom: 1px solid #eff2f5 !important;
    }
  }
  .border-radius-25px {
    border-radius: 20px !important;
  }
  .text_users {
    border: 1px solid #656776;
    color: #656776;
    border-radius: 17px;
    height: 18px !important;
    width: 28px;
    padding: 7px 11px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px !important;
  }
}
.bdr-none {
  border: none !important;
}
.input_maintain {
  width: 1rem !important;
  height: 1rem !important;
}

.mobile_direction_column{
  @media (max-width:768px){
   &{
    flex-direction: column;
   }
  }
}
@media (max-width:425px){
  .mb-sm-3{
    margin-bottom: 9px;
  }
}
.list_hide{
 word-break: break-all !important;
}
.text{
  white-space: nowrap !important;
}
.word_break{
  word-break: break-word !important;
}