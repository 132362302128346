//
// NoUiSlider
//

// Base
.noUi-target {
  border: 0;
  background: if(isDarkMode(), $gray-200, $gray-100);
  box-shadow: none;

  &.noUi-horizontal {
    height: 15px;

    .noUi-handle {
      width: 24px;
      height: 24px;
      top: -4.5px;
      border-radius: 50%;
      outline: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  &.noUi-vertical {
    height: 150px;
    width: 15px;

    .noUi-handle {
      width: 24px;
      height: 24px;
      right: -4.5px;
      border-radius: 50%;
      outline: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .noUi-connect {
    background: $component-active-bg;
  }

  .noUi-handle {
    border: 1px solid $gray-200;
    box-shadow: 0 3px 6px -3px rgba(#000, 0.7);
  }

  // Small size
  &.noUi-sm {
    @include nouislider-size(6px, 20px, 20px, 7px);
  }

  // Large size
  &.noUi-lg {
    @include nouislider-size(18px, 30px, 30px, 6px);
  }
}

// Tooltip
.noUi-tooltip {
  box-shadow: $tooltip-box-shadow;
  background: #074b87;
  color: white;
  font-size: $tooltip-font-size;
  border: 0;
  padding: 0.5rem 0.75rem;
  @include border-radius($tooltip-border-radius);
}

.noUi-target.noUi-horizontal .noUi-handle {
  background: #074b87;
  cursor: pointer;
}

.noUi-active .noUi-tooltip {
  display: block;
}
