.task-status {
  cursor: pointer;
  .menu-sub {
    background-color: transparent !important;
    box-shadow: none !important;
    left: 0px;
    padding: 4px 0 !important;
    .menu-item {
      color: white !important;
      border-radius: 5px;
      border: 1px solid;
      margin-bottom: 2px !important;
      padding: 0 !important;
      background-color: grey;
      width: fit-content;
      height: 23px;
      display: flex;
      font-size: 11px;
      .menu-title {
        color: white !important;
      }
      .todo {
        background-color: red;
      }
      .Inprogress {
        background-color: green;
      }
      .Done {
        background-color: grey;
      }
    }
  }
  .bg-green {
    background: #0097a8;
    font-weight: 500;
    color: white;
    min-width: 60px;
    font-size: 12px;
    border-radius: 3px;
    padding: 0 2px 0px 5px;
    width: max-content !important;
  }
  .padding-top-1px {
    padding-top: 1px !important;
  }
}
.main-style-due-date {
  i {
    position: absolute;
    left: 116px;
    color: #bbbbbb;
    top: -2px;
  }
}
.label-due {
  cursor: pointer;
}
i.fa.fa-angle-down.due-on {
  position: relative;
  left: 4px;
  top: 2px;
}
.priority-div {
  box-shadow: none;
  left: -21px;
  background: transparent;
}
.css-g1d714-ValueContainer {
  position: relative;
  // top:-6px;
}
.css-12jo7m5 {
  font-size: 13px !important;
  padding: 0 !important;
}
.css-1rhbuit-multiValue {
  //   top: 3px;
  position: relative;
  padding: 0 0 0 7px !important;
}
.task_pop_up_activity {
  padding: 22px !important;
  .card-body {
    padding: 1.5rem 23px !important;
  }
  .timeline-content {
    padding: 1.2rem 1.5rem 0.95rem 2.5rem !important;
    // padding: 0.95rem 1.5rem 0.95rem 2.5rem !important;
    // min-height: 9vh;
    left: -37px;
    top: -4px;
  }
  .timeline .timeline-content {
    margin-bottom: 0 !important;
  }

  .timeline-media {
    left: -33px;
    border: 2px solid white !important;
    img {
      z-index: 9999;
      width: 48px !important;
      height: 48px !important;
      border: 5px solid white;
    }
  }
  .timeline.timeline-3 .timeline-items .timeline-item {
    margin-left: 25px;
    border-left: none !important;
    padding: 0 0 20px 35px;
    position: relative;
  }
  .mr-2 {
    display: contents !important;
  }
  .text-dark-75 {
    flex-basis: 76%;
    // margin-left:15px !important;
    // margin-top: 9px;
  }
  .card-toolbar {
    & {
      @media (max-width: 768px) {
        white-space: nowrap;
      }
    }
  }
  .text-muted {
    & {
      @media (max-width: 768px) {
        white-space: nowrap;
      }
    }

    // margin-left:15px !important;
    // margin-top: 9px;
  }
  .text-dark-75 + span {
    font-size: 12px;
  }
}
.attachments_popup {
  img {
    height: 26px;
    width: 26px;
    border-radius: 100px;
  }
  .activity-icons .activity-inner {
    margin-right: 0 !important;
  }
}
.pro-main-div {
  width: 38%;
}

.task_header_popup {
  align-items: inherit !important;
}
