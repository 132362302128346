.timeSlotItem {
    background-color: #f8f9fa;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
}

.timeSlotItemButton {
    padding: 6px 6px !important;
}

.slot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}

.slot-centered-text {
    font-size: 1.4rem;
    text-align: center;
}