.heading {
  .p {
    font-size: 26px;
    font-weight: 500;
    color: #181c32;
  }
}

.tableHeading {
  font-size: 14px;
  color: #a1a5b7;
}

.tableRow {
  font-size: 14px;
  color: #181c32;
}

.drag-drop-card-main .drag-drop-card {
  min-height: 153.94px;
  border-radius: 10px;
  // border: 0.75px solid #c9cbd9 !important;
  opacity: 1;
  padding: 20px;
  height: 100%;
}
.drag-drop-card-main .drag-drop-card .card-title {
  min-height: auto !important;
  color: #181c32;
  font-size: 16px;
  font-weight: 500;
  @media screen and (max-width:576px) {
    font-size: unset;
  }
}
.subheading {
  color: #a1a5b7;
  font-size: 14px;
  @media screen and (max-width:576px) {
    font-size: unset;
  }
}
.description {
  color: #656776;
  font-size: 14px;
  overflow: hidden;
  // height: 67px;
  ul {
    li {
      padding-bottom: 7px;
    }
  }
  @media screen and (max-width:576px) {
    font-size: unset;
  }
}
.versionContent {
  color: #00a1f7;
  font-size: 14px;
  @media screen and (max-width:576px) {
    font-size: unset;
  }
}
.createdAtContent {
  color: #51cd92;
  font-size: 14px;
  @media screen and (max-width:576px) {
    font-size: unset;
  }
}
.border-card-grey {
  border: 1px solid #c9cbd9 !important;
}
