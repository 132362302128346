

// Lightbox model
.ReactModalPortal,
.ReactModal__Overlay .ReactModal__Overlay--after-open {
  z-index: 1100 !important;
}

//
// Modal
//

// Base
.modal {
  // Mobile header
  .modal-header {
    align-items: center;
    justify-content: space-between;
    @include border-top-radius($modal-content-border-radius);
    .task-status {
      .select-ui {
        .css-1s2u09g-control {
          min-height: 15px !important;
          height: 20px !important;
          background-color: #0097a8 !important;
          border: 1px solid #0097a8 !important;
          padding: 0px 0 0 0 !important;
          align-items: flex-start;
          .css-319lph-ValueContainer {
            padding: 0 !important;
            .css-tj5bde-Svg {
              display: inline-block;
              fill: currentColor;
              line-height: -6;
              stroke: currentColor;
              stroke-width: 0;
              padding-bottom: 3px;
            }
            .css-14el2xx-placeholder {
              color: white !important;
              white-space: nowrap !important;
            }
            .css-6j8wv5-Input {
              color: white !important;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        }
        .css-tlfecz-indicatorContainer {
          padding: 0 !important;
        }
        .css-1okebmr-indicatorSeparator {
          display: none;
        }
        .css-14el2xx-placeholder {
          white-space: nowrap;
        }
      }
    }

    // Headings
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  // Mobile dialog
  .modal-dialog {
    outline: none !important;
  }
}

// Sticky
.modal.modal-sticky {
  &.modal-sticky-bottom-end {
    $sticky-width: 500px;
    $sticky-width-lg: 600px;

    padding: 0 !important;
    max-width: $sticky-width;
    height: auto;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 25px;
    right: 25px;
    margin: 0;
    box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
    @include border-radius($border-radius);

    .modal-dialog {
      position: static;
      max-width: $sticky-width;
      width: auto;
      margin: 0;
      @include border-radius($border-radius);

      .modal-content {
        border: 0;
        @include border-radius($border-radius);
      }
    }

    &.modal-sticky-lg {
      max-width: $sticky-width-lg;

      .modal-dialog {
        max-width: $sticky-width-lg;
      }
    }

    // Tablet & Mobile Modess
    @include media-breakpoint-down(md) {
      bottom: 10px;
      right: 10px;
      left: 10px;
      width: auto;
    }
  }
}

/* custom css */
.select-custom-ui {
  border: 1px solid white;
  font-size: 14px;
  font-weight: 500;
  &:focus-visible {
    box-shadow: none !important;
    border: 1px solid white !important;
    outline: none !important;
  }
}
.main-style-class {
  & .fa-angle-down {
    &:before {
      content: '';
      color: black;
      font-size: 15px;
      margin-left: 4px;
    }
  }
  // input{
  //   border:none !important;
  // }
}
.icon-style-class {
  &:before {
    content: '';
    color: black;
    font-size: 15px;
    margin-left: 4px;
  }
}
.pro-main-div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 111px;
  span {
    font-size: 12px !important;
  }
  @media (max-width: 768px) {
    & {
      width: 111px;
    }
  }
  // @media  (max-width:500px){
  //   &{
  //     width: 100%;
  //   }
  // }
}
.img-width {
  height: 27px;
}
.img-width-style {
  height: 19px;
  margin-bottom: 4px;
  margin-left: 5px;
}
.select-none-ui {
  position: relative !important;
  right: -57px !important;
  top: 3px !important;

  .css-319lph-ValueContainer,
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-26l3qy-menu {
    width: auto !important;
    margin-top: 0 !important;
    background-color: transparent !important;
    // width: 232% !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    outline: none !important;
    li {
      background-color: #0097a8 !important;
    }
  }
  .css-4ljt47-MenuList {
    border: 1px solid transparent !important;
    box-shadow: none !important;
    outline: none !important;
    font-size: 13px !important;
  }
  .css-4ljt47-MenuList {
    border: 1px solid transparent !important;
    box-shadow: none !important;
    outline: none !important;
    font-size: 13px !important;
    & div {
      color: inherit;
      cursor: default;
      display: flex !important;
      margin: 2px 0;
      color: white;
      background: red;
      white-space: nowrap;
      font-size: 11px;
      height: 22px;
      padding: 0px 4px !important;
      border-radius: 5px;
      width: -moz-fit-content;
      width: fit-content;
      -webkit-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      box-sizing: border-box;
      align-items: center !important;
    }
  }
  .css-1s2u09g-control {
    border-color: white !important;
    &:focus-visible {
      border: 1px solid white !important;
      box-shadow: none !important;
    }
  }
  //   @media (max-width:540px){
  //     & {

  //         position: relative !important;
  //         right: -276px !important;
  //         top: 3px !important;
  //   }
  // }
  @media (max-width: 768px) {
    & {
      position: relative !important;
      left: 100% !important;
      top: 3px !important;
    }
  }
}
.label-style {
  font-weight: 500;
  font-size: 14px;

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-1pahdxg-control {
    box-shadow: none !important;
    border-color: hsl(0, 0%, 80%) !important;
    // min-height: 1px !important;
    min-height: 28px !important;
    // width: 161% !important;
    align-items: center !important;
    .css-1gtu0rj-indicatorContainer {
      padding: 0 !important;
      position: relative !important;
      top: -2px !important;
    }
  }
  .css-1s2u09g-control {
    // min-height: 1px !important;
    min-height: 28px !important;
    // width: 161% !important;
    //  width: 150% !important;
    //  border-color: white !important;
    &:focus-visible {
      border-color: white !important;
      border: 2px solid white !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .css-tlfecz-indicatorContainer {
    padding: 0 !important;
    position: relative !important;
    top: -2px !important;
  }
  .css-319lph-ValueContainer {
    padding: 0px 28px 0 8px !important;
    align-items: center !important;
    .css-14el2xx-placeholder {
      white-space: nowrap;
    }
  }
  // .css-26l3qy-menu {
  //   box-shadow: none !important;
  //   width: fit-content !important;
  //   background-color: transparent !important;
  //   .css-4ljt47-MenuList {
  //     // border: 1px solid white !important;
  //     box-shadow: none !important;
  //     padding: 0 !important;
  //     outline: none !important;
  //     & div {
  //       color: inherit;
  //       cursor: default;
  //       display: flex !important;
  //       margin: 2px 0;
  //       color: white;
  //       background-color: grey;
  //       // background-color: hsl(0, 0%, 90%);
  //       white-space: nowrap;
  //       font-size: 11px;
  //       height: 22px;
  //       padding: 0px 4px !important;
  //       border-radius: 5px;
  //       width: -moz-fit-content;
  //       width: fit-content;
  //       -webkit-user-select: none;
  //       user-select: none;
  //       -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //       box-sizing: border-box;
  //       align-items: center !important;
  //     }
  //   }
  // }
}
.select-ui {
  .css-1pahdxg-control {
    min-height: 15px !important;
    height: 20px !important;
    background-color: #0097a8 !important;
    border: 1px solid #0097a8 !important;
    padding: 0px 0 0 0 !important;
    align-items: flex-start;

    .css-1gtu0rj-indicatorContainer {
      padding: 0 !important;
    }
    .css-6j8wv5-Input {
      margin: 0 !important;
      padding: 0 !important;
    }
    .css-14el2xx-placeholder {
      color: white !important;
    }
    .css-319lph-ValueContainer {
      padding: 0 !important;
      .css-tj5bde-Svg {
        display: inline-block;
        fill: currentColor;
        line-height: -6;
        stroke: currentColor;
        stroke-width: 0;
        padding-bottom: 3px;
      }
      .css-14el2xx-placeholder {
        color: white !important;
      }
      .css-6j8wv5-Input {
        color: white !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  .css-26l3qy-menu {
    width: auto !important;
    margin-top: 0 !important;
    background-color: transparent !important;
    border: 1px solid white !important;
    box-shadow: none !important;
    outline: none !important;

    .css-4ljt47-MenuList {
      border: 1px solid white !important;
      box-shadow: none !important;
      outline: none !important;
      padding: 0 !important;

      & div {
        color: inherit;
        cursor: default;
        display: flex !important;
        margin: 2px 0;
        color: white;
        background: red;
        white-space: nowrap;
        font-size: 11px;
        height: 22px;
        padding: 0px 4px !important;
        border-radius: 5px;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        align-items: center !important;
      }
      & div:nth-of-type(2) {
        background-color: #9fa1aa !important;
      }
      & div:nth-of-type(3) {
        background-color: #004b87 !important;
      }
    }
  }
}
.options-elements {
  .main-style-class {
    label {
      font-size: 15px !important;
      font-weight: 600 !important;
    }
    .select-custom-ui {
      font-size: 15px !important;
      font-weight: 600 !important;
    }
  }
}
/* custom css */

.fs-15w {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.word-alignment {
  cursor: pointer;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  max-width: 81%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .small-screen-direction {
    flex-direction: column;
  }

  .options-elements {
    .main-style-class {
      flex-basis: 32%;
    }
  }
}
@media (max-width: 540px) {
  .options-elements {
    .main-style-class {
      flex-basis: 100%;
    }
  }
  .label-style {
    margin-top: 23px;
  }
  .main-style-due-date {
    flex-basis: 32% !important;
  }
}

.color-light-grey {
  color: #a1a5b7 !important;
}
.eye {
  i {
    &:hover {
      color: grey !important;
    }
  }
}
