#datepicker-portal,
#datepicker-portal > div.react-datepicker-wrapper,
#datepicker-portal
  > div
  > div.react-datepicker__input-container
  #datepicker-portal
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
