%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}
.form_bg_light {
  .form-control,
  .form-select {
    background-color: #f9f9f9 !important;
  }
}
.btn-blue {
  background-color: #0368cd !important;
  color: white !important;
  padding: 7px 33px !important;
}
.bg-lightest {
  border: 1px solid #f5f5f7;
  padding: 8px;
  .btn-white {
    color: #0368cd !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  img {
    width: 15px !important;
    margin-bottom: 4px !important;
  }
}
.bg_grey_content_none {
  background: #f5f5f7 !important;
  color: #f5f5f7 !important;
  height: 18px;
}
.h-12px {
  height: 12px !important;
}
.color-grey {
  color: #a1a5b7;
}
.bd-50px {
  border-radius: 50px !important;
}
.border-light-blue {
  background-color: #f1faff !important;
  border: 1px solid #f1faff !important;
}
.text-style-count {
  border: 1px solid #656776;
  color: #656776;
  border-radius: 17px;
  height: 18px !important;
  width: 28px;
  padding: 7px 11px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
}
.blur-image-data {
  display: flex;

  div {
    width: 37px !important;
    background: #f5f5f7 !important;
    height: 37px !important;
    // z-index: 9999999 !important;
    border: transparent !important;
    margin-top: 12px;
    border-radius: 50% !important;
    &:not(:first-child) {
      margin-left: -18px !important;
    }
  }
}
.border_bottom_none{
  border-bottom: 1px solid transparent !important;
}
.w-173pr{
  width: 173% !important;
}
.mobile_screen{
  @media (max-width:425px) {
    padding: 20px !important;
  }
}