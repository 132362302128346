.react-calendar {
    width: 800px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-color: #dedede;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.125em;
}

.react-calendar__navigation button {
    color: #0368cd;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #0368cd;
    border-radius: 6px;
}

.react-calendar__tile--now {
    background: #dfefff;
    border-radius: 6px;
    font-weight: bold;
    color: #0368cd;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #dfefff;
    border-radius: 6px;
    font-weight: bold;
    color: #0368cd;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active {
    background: #0368cd;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #0368cd;
    color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
}

.react-calendar__tile--range {
    background: #f8f8fa;
    color: #0368cd;
    border-radius: 0;
}

.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #0368cd;
    color: white;
}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #0368cd;
    color: white;
}