.view_all_dashboard {
  justify-content: center;
  border: 1px solid #f5f5f7;
  padding: 16px 0;
  border-radius: 5px;
  background: #f5f5f7;
  height: 100px;
  width: 100px;

  &:hover {
    background-color: #f3f3f3;
  }

  .color-grey-dark {
    color: #a1a5b7;
  }

  p {
    font-size: 14px;
  }
}

.spaces_header {
  .bd-right-none {
    border-right: 0 !important;
    border-bottom-left-radius: 4px;
  }

  .bd-left-none {
    border-left: 0 !important;
  }

  .input-group-text {
    i {
      font-size: 16px !important;
    }
  }

  .form_control_space {
    border: 1px solid #eeeeee;
    // border-radius: 5px !important;
    color: #c2c2c2;

    &:focus {
      border: 1px solid #eeeeee;
    }

    &::placeholder {
      color: #c2c2c2 !important;
      font-size: 12px !important;
    }
  }

  .input-group {
    width: 55% !important;
  }

  img {
    width: 14px !important;
  }

  .ligh-btn {
    height: 26.94px;
    margin-left: 15px;
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.header_spaces {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 14px;
}

.add_btn_spaces {
  align-items: center;

  div {
    border: 1px solid #c9cbd9;
    border-radius: 5px;

    img {
      width: 75px;
    }
  }
}

.modal_aligned_right {
  right: -381px;
  top: 142px;
}

.dashboard_space {
  .col-1 {
    margin: 0 4px;
  }

  align-items: flex-start;

  .image_ui {
    width: 100%;
    // padding: 15px;
  }

  .para_ui {
    font-size: 14px;
    white-space: nowrap;
    margin-top: 9px;
    font-weight: 500;
  }
}

.dropdown_dialog {
  transform: unset !important;
  position: absolute !important;
  top: 0 !important;
  height: 100% !important;

  .fa-times:before {
    font-size: 17px;
  }
}

.input_dashboard {
  .form-check-input {
    width: 1rem !important;
    height: 1rem !important;
  }

  .form-check {
    display: flex !important;
    align-items: center;
  }

  .form-check-label {
    margin-left: 6px;
    font-size: 12px !important;
  }
}

.dashboard_select {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #a1a5b7;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 12px !important;
}

.f-13px {
  font-size: 12px !important;
  font-weight: 500;
}

.border-radius-0px {
  border-radius: 0px !important;
}

.border-radius-15px {
  border-radius: 15px !important;
}

.height_min {
  min-height: 660px;
  position: relative;
}

.icon_ui {
  .noUi-tooltip {
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    background: transparent;
    color: black;
    font-size: 0.925rem;
    border: 0;
    padding: 0.2rem 0.75rem;
    border-radius: 0.475rem;
    font-size: 10px;
  }

  .noUi-target.noUi-sm .noUi-handle {
    width: 14px !important;
    height: 14px !important;
    top: -4px !important;
  }

  .noUi-target.noUi-horizontal .noUi-handle {
    background: #0368cd;
    cursor: pointer;
  }
}

.popup_setting {
  .btn-primary {
    background-color: #0368cd !important;
  }
}

.btn-focus-white {
  &:focus {
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent !important;
  }

  &.active {
    background-color: transparent !important;
  }
}

.text-blue {
  color: #0368cd !important;
}

.card_create {
  min-height: 179.94px !important;
}

.bg-lighter {
  background-color: #f9f9f9;
}

.cards-wrapper {
  margin-left: -10px !important;
  margin-right: -10px !important;

  >div {
    margin-right: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.form-check-input:checked {
  background-color: #0368cd !important;
  border-color: #0368cd !important;
}

.masonry-title-memeber {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 80px !important;
}

.todo-card-title-memeber,
.masonry-card-title-memeber {
  // display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px !important;
}

.fs-26px {
  font-size: 22px;
}

@media screen and (max-width: 425px) {
  .spaces_header {
    flex-wrap: wrap;

    .input-group {
      width: 100% !important;
    }
  }

  .header_spaces {
    align-items: baseline;
  }
}

.margins-dashboard {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;

  @media (max-width:540px) {
    & {

      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
  }

  @media (max-width:398px) {
    & {
      justify-content: center;
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }

  }
}

.padding_bottom_header {
  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media (max-width:575px) {
  .padding_bottom_header {
    padding-bottom: 12px;
  }

  .px-sm-12 {
    .row {
      padding-bottom: 12px;
    }
  }
}


.page-loading {
  width: 100%;
  margin: 10% auto 0;
  padding: 20px;
  background: #e5e5e5;
}

.page-title {
  margin-top: 5px;
  margin-bottom: 10px;
}

.new-div {
  // width: 92px;
  background-color: green;
  /* margin-bottom: 56px; */
  height: 52%;
  width: 9%;
  z-index: 999;
  border-radius: 8px;
  // animation-duration: 1.7s;
  // animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
  // animation-name: placeholderAnimate;
  // background: #f6f7f8;
  // background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
}

.page-content-loading {
  background: #fff;
  padding: 0 !important;
  margin: 0;
}

.placeholder-content {
  // height: 205px;
  // overflow: hidden;
  background: white;
  // position: relative;
  // -webkit-animation-duration: 1.7s;
  // animation-duration: 1.7s;
  // -webkit-animation-fill-mode: forwards;
  // animation-fill-mode: forwards;
  // -webkit-animation-iteration-count: infinite;
  // animation-iteration-count: infinite;
  // -webkit-animation-timing-function: linear;
  // animation-timing-function: linear;
  // -webkit-animation-name: placeholderAnimate;
  // animation-name: placeholderAnimate;
  // background: #f6f7f8;
  // background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
}

.p-loading {

  visibility: hidden;
  margin-bottom: 0;
  font-size: 12px;


}

.div-load {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 100%, #eee 33%);

  margin-top: 12px;
  height: 13px;
}

.placeholder-content_item {

  background-color: green;
  /* margin-bottom: 56px; */
  height: 100px;
  width: 100px;
  z-index: 1;
  border-radius: 8px;

  animation-duration: 7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 100%, #eee 33%);





  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;













  // width: 100%;
  // // height: 100px;
  // height: 115px;
  // position: absolute;
  // background: #fff;
  // z-index: 2;

  // width: 100%;
  // height: 20px;
  // position: absolute;
  // background: #fff;
  // z-index: 2;
}

// .placeholder-content_item:nth-child(2) {
//   animation-duration: 1.7s;
//   animation-fill-mode: forwards;
//   animation-iteration-count: infinite;
//   animation-timing-function: linear;
//   animation-name: placeholderAnimate;
//   background: #f6f7f8;
//   background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
//   // top: 20px;
//   // left: 0;
//   // width: 10%;
//   height: 89px;
// }

// .placeholder-content_item:after,
// .placeholder-content_item:before {
//   width: inherit;
//   height: inherit;
//   content: "";
//   position: absolute;
// }

// .placeholder-content_item:nth-child(1) {
//   top: 0;
//   left: 0;
// }

// .placeholder-content_item:nth-child(2) {
//   top: 20px;
//   left: 0;
//   width: 10%;
//   height: 90px;
// }

// .placeholder-content_item:nth-child(3) {
//   top: 0px;
//   left: 0;
//   width: 10%;
//   height: 100%;
// }

// .placeholder-content_item:nth-child(4) {
//   top: 20px;
//   width: 20px;
//   left: 170px;
//   height: 90px;
// }

// .placeholder-content_item:nth-child(5) {
//   top: 40px;
//   left: 190px;
//   height: 12px;
// }

// .placeholder-content_item:nth-child(6) {
//   top: 75px;
//   left: 190px;
//   height: 12px;
// }

// .placeholder-content_item:nth-child(7) {
//   top: 20px;
//   right: 0;
//   width: 23%;
//   height: 20px;
// }

// .placeholder-content_item:nth-child(8) {
//   top: 0;
//   right: 0;
//   width: 10%;
//   height: 100%;
// }

// .placeholder-content_item:nth-child(9) {
//   top: 110px;
//   height: 17px;
//   left: 0;
// }

// .placeholder-content_item:nth-child(10) {
//   top: 149px;
//   height: 12px;
//   left: 0;
// }

// .placeholder-content_item:nth-child(11) {
//   top: 183px;
//   left: 0;
//   height: 100%;
// }

@-webkit-keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }

  100% {
    background-position: 650px 0;
  }
}






// ----------------------------



body {
  background-color: #eee;
}

.timeline-title {
  margin: 0 auto;
  max-width: 472px;
}

/**
* main style
*/

.timeline-item {
  background-color: #fff;
  // border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;

  margin: 0 auto;
  // max-width: 472px;

  // min-height: 200px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}


.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.animated-div-para {
  background: red;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 12px;


  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;

  position: relative;
}

.margin-b-30px {
  margin-bottom: 30px;
}