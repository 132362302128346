.multisel {
  font-family: inherit;
  font-size: 1.1rem;
}

.multisel .multi-select__control,
.multisel .multi-select__control:hover {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  box-shadow: none;
  padding: 2px 0 1px 0;
}

.multisel .multi-select__menu .multi-select__menu-list .multi-select__option {
  background: none !important;
}
.stepper .multisel .multi-select__menu-list {
  max-height: 160px;
}
.multisel .multi-select__menu .multi-select__menu-list .multi-select__option:hover {
  background: #073b66 !important;
  color: #ffffff;
}

.multisel .multi-select__multi-value {
  background: #eef3f7;
  font-family: inherit;
  font-size: 1.1em;
  font-weight: 500;
  color: #5e6278;
}

.multisel .multi-select__multi-value__label,
.multisel .multi-select__multi-value__remove {
  color: #5e6278;
  font-weight: 500;
}

.multisel .multi-select__indicator-separator {
  background: none;
}

.multisel .multi-select__indicator {
  color: #9094b1;
  padding: 8px 14px 8px 8px;
}

.multisel .multi-select__placeholder {
  color: #5e6278;
  font-weight: 500;
}

.multisel .multi-select__multi-value__remove:hover {
  background: none;
  color: #004b87;
}

.multisel .multi-select__menu {
  background-color: #eef3f7;
  border-color: #eef3f7 !important;
  z-index: 10;
}
