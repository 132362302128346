.forgot_password_ui {
  input {
    background-color: #f9f9f9 !important;
    border-color: #f9f9f9 !important;
    border: 1px solid #f9f9f9 !important;
  }

  .heading {
    p {
      font-size: 24px;
      color: #181c32;
      margin-bottom: 6px;
      font-weight: 500;
    }
  }

  .subheading {
    p {
      font-size: 18px;
      color: #c9cbd9 !important;
    }
    .link {
      color: #0368cd !important;
      &:hover {
        color: #0859aa !important;
      }
    }
  }

  .forget-password {
    font-size: 16px;
    color: #c9cbd9;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .submitbtn {
    background-color: #0368cd !important;
    color: white !important;
    border-radius: 8px !important;
    &:hover {
      color: white !important;
      background-color: #0859aa !important;
    }
  }
  .bg-light-grey {
    background-color: #f9f9f9 !important;
  }
  .back_signIn{
    &:hover{
      color: #073b6e !important
    }
  }
}
