.stepper-main {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .stepper-nav {
      .stepper-steps {
          display: flex;
          align-items: flex-end;
          // justify-content: space-between;
          flex-wrap: wrap;
          .stepper-step {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              // flex-grow: 1;
              border-top-left-radius: 0.5rem;
              border-top-right-radius: 0.5rem;
              
              &:not(:last-child) {
                margin-right: 4px;
              }
              // &[data-stepper-state=current] {
              //     background-color: #ffffff;
              //     .stepper-wrapper {
              //         .stepper-number {
              //             color: #ffffff;
              //             background-color: #3699FF;
              //         }
              //         .stepper-label {
              //             .stepper-title {
              //                 color: #3699FF;
              //             }
              //         }
              //     }
              // }
              // &[data-total-steps="2"] {
              //     .stepper-step {
              //         -webkit-box-flex: 0;
              //         -ms-flex: 0 0 calc(50% - 0.25rem);
              //         flex: 0 0 calc(50% - 0.25rem);
              //         width: calc(50% - 0.25rem);
              //     }
              // }
              // &[data-total-steps="3"] {
              //     .stepper-step {
              //         -webkit-box-flex: 0;
              //         -ms-flex: 0 0 calc(33.3333333333% - 0.25rem);
              //         flex: 0 0 calc(33.3333333333% - 0.25rem);
              //         width: calc(33.3333333333% - 0.25rem);
              //     }
              // }
              // &[data-total-steps="4"] {
              //     .stepper-step {
              //         -webkit-box-flex: 0;
              //         -ms-flex: 0 0 calc(25% - 0.25rem);
              //         flex: 0 0 calc(25% - 0.25rem);
              //         width: calc(25% - 0.25rem);
              //     }
              // }
              .stepper-wrapper {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  color: #3F4254;
                  padding: 1.1rem 1.5rem;
                  justify-content: center;
                  background-color: #F3F6F9;
                  border-top-right-radius: 6px;
                  border-top-left-radius: 6px;
                  &.active,
                  &:hover {
                      background-color: #fff;
                      &.nav-link {
                          .stepper-label {
                              .stepper-title {
                                  color: #004b87;
                              }
                          }
                        border-color: transparent;
                      }
                  }
                  // .stepper-number {
                  //     font-size: 1.3rem;
                  //     font-weight: 600;
                  //     -webkit-box-flex: 0;
                  //     -ms-flex: 0 0 2.75rem;
                  //     flex: 0 0 2.75rem;
                  //     height: 2.75rem;
                  //     width: 2.75rem;
                  //     display: -webkit-box;
                  //     display: -ms-flexbox;
                  //     display: flex;
                  //     -webkit-box-align: center;
                  //     -ms-flex-align: center;
                  //     align-items: center;
                  //     -webkit-box-pack: center;
                  //     -ms-flex-pack: center;
                  //     justify-content: center;
                  //     background-color: rgba(54, 153, 255, 0.08);
                  //     color: #3699FF;
                  //     margin-right: 1rem;
                  //     border-radius: 0.5rem;
                  // }
                  .stepper-label {
                      display: flex;
                      flex-direction: column;
                      .stepper-title {
                        font-size: 14px;
                        text-transform: capitalize;
                        font-weight: 600;
                      }
                      .stepper-desc {
                        font-weight: 400;
                        font-size: 12px;
                      }
                  }
              }
          }
      }
  }
}
.stepper-main {
  &.third {
    .stepper-nav {
      .stepper-steps {
        .stepper-step {
          .stepper-wrapper {
            background-color: #004b87;
            color: #fff;
              &.active,
              &:hover {
                  background-color: #fff;
                  &.nav-link {
                      .stepper-label {
                        .stepper-title ,
                        .stepper-desc
                             {
                              color: #3F4254;
                          }
                      }
                    border-color: transparent;
                  }
              }
              .stepper-label {
                  display: flex;
                  flex-direction: column;
                  .stepper-title {
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 600;
                  }
                  .stepper-desc {
                    font-weight: 400;
                    font-size: 12px;
                  }
              }
          
          }
        }
      }
    }

  }
}
.stepper-main {
  &.fourth {
    .stepper-nav {
      .stepper-steps {
        .stepper-step {
          .stepper-wrapper {
            background-color: #009b8d;
            color: #fff;
              &.active,
              &:hover {
                  background-color: #fff;
                  &.nav-link {
                      .stepper-label {
                        .stepper-title ,
                        .stepper-desc
                            {
                              color: #3F4254;
                          }
                      }
                    border-color: transparent;
                  }
              }
              .stepper-label {
                  display: flex;
                  flex-direction: column;
                  .stepper-title {
                    font-size: 14px;
                    text-transform: capitalize;
                    font-weight: 600;
                  }
                  .stepper-desc {
                    font-weight: 400;
                    font-size: 12px;
                  }
              }
          
          }
        }
      }
    }

  }
}
  // @media (max-width: 767.98px) {
  //   .stepper-main {
  //     .stepper-nav {
  //       .stepper-steps {
  //         flex-direction: column;
  //         align-items: flex-start;
  //         .stepper-step {
  //           flex: 0 0 100% !important;
  //           position: relative;
  //           width: 100% !important;
  //           .stepper-wrapper {
  //             flex: 0 0 100%;
  //             padding: 0.5rem 2rem;
  //           }
  //         }
  //       }
  //     }
  //   } 
  // }